import React from "react";
import { ContextProviderComponent } from "src/layouts/Context.js";
import { graphql } from "gatsby";
import Footer from "src/components/global/Footer.js";
import GlobalStyle from "src/assets/styles/globalStyles.js";

// import Cookies from "src/components/global/Cookies.js";

const HomePage = ({ data, children, pageContext }) => {
  return (
    <>
      {/* <Cookies />  */}
      <ContextProviderComponent>
        <GlobalStyle />
        {children}
        <Footer />
      </ContextProviderComponent>
    </>
  );
};

export default HomePage;
