const typographySizes = {
  xxs: 9,
  xs: 12,
  s: 16,
  sm: 20,
  m: 26,
  l: 38,
};

export default typographySizes;
