import * as React from "react";
import styled from "styled-components";
import Logo from "src/assets/svg/ds-dao-logo-black.inline.svg";
import colors from "src/assets/styles/colors.js";
import { StaticQuery, graphql } from "gatsby";
import distances from "src/assets/styles/distances.js";
import typographySizes from "src/assets/styles/typographySizes.js";
import mediaQuery from "src/assets/styles/mediaQuery";

const Wrapper = styled.div`
  width: 100%;
  background-color: ${colors.white};
  padding: ${distances.gap * 2}rem ${distances.pageMarginBig}rem;

  width: calc(100% - ${distances.pageMarginBig * 2}rem);
  position: relative;

  z-index: 10;

  @media (max-width: ${mediaQuery.laptop}) {
    padding: ${distances.gap * 2}rem 30rem;
    width: calc(100% - 60rem);
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    justify-content: flex-start;
    margin-top: ${distances.gap * 2}rem;
  }
`;

const LinkFooter = styled.a`
  font-size: ${typographySizes.s}rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  margin-left: ${distances.gap}rem;
  text-decoration: none;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    margin-right: ${distances.gap}rem;
  }
`;

const We3 = styled.p`
  font-size: 12rem;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5;
  position: absolute;
  margin-top: 20rem;
  a {
    font-size: inherit;
    text-decoration: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: block;
  }
`;

const StyledLogo = styled(Logo)`
  width: 254rem;
  @media (max-width: ${mediaQuery.mobile}) {
    width: 200rem;
  }
`;

const Footer = ({ top }) => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          strapiGeneral {
            Link {
              Link
              Name
            }
          }
        }
      `}
      render={(data) => (
        <Wrapper top>
          <TopWrapper>
            <StyledLogo />
            <LinksWrapper>
              {data.strapiGeneral.Link.map((pos) => (
                <LinkFooter href={pos.Link} target="_blank">
                  {pos.Name}{" "}
                </LinkFooter>
              ))}
            </LinksWrapper>
          </TopWrapper>
          <We3>
            Projekt i wdrożenie:{" "}
            <a href="https://we3studio.pl/" target="_blank">
              we3studio.pl
            </a>
          </We3>
        </Wrapper>
      )}
    />
  );
};

export default Footer;
